@charset "UTF-8";
@import "core/functions";//mixinの設定
@import "core/brackpoint";//ブレイクポイント設定

#MV-MOVIE{
  width:100%;
  position:relative;
}
#MV-MOVIE video{
  width:100%;
}
#MV-MOVIE .scroll{
  bottom:0px;
  left:50%;
  position:absolute;
  transform: translateX(-50%) ;
}

#MOVIE-BUTTON{
  width: 90px;
  height: 90px;
  position: absolute;
  bottom:50px;
  right:50px;

  img{
    width: 100%;
  }

  @media screen and (max-width: 749px){
    width: 15vw;
    height: 15vw;

    bottom:5vw;
    right:5vw;
  }
}
@media screen and (max-width: 920px){
  #MV-MOVIE .scroll{
    display:none;
  }
  .headmenu{
    display:none;
  }
}
@media screen and (min-width: 750px){
  #video-SP{
    display: none;
  }
}
@media screen and (max-width: 749px){
  #video-PC{
    display: none;
  }
}
